.viewport {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.auth-main-container {
  min-height: 100%;
}

.left-panel {
  height: calc(100% - 85px);
  background-color: #002941;
  position: absolute;
  width: 155px;
  top: 85px;
  z-index: 5000;
}

.auth-content-container {
  background-color: #e8eff6;
  width: 100%;
  display: flex;
  min-height: 100%;
}

.auth-content-header {
  background-color: #ffffff;
  height: 85px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.auth-logo-cont {
  margin: 30px 0 0 25px;
  width: 118px;
  height: 24px;
}

.auth-content-main {
  height: calc(100% - 90px);
  width: calc(100% - 165px);
  margin-left: auto;
  margin-right: 5px;
  padding-top: 0.5rem;
  position: relative;
  overflow: hidden;
}

.auth-content-content {
  background-color: #ffffff;
  left: 75px;
  width: 100%;
  height: 100%;
  padding: 25px;
  max-height: calc(100vh - 80px); /* Adjust based on header and footer height */
  overflow-y: scroll;
}

.auth-content-header h5 {
  font-weight: 700;
  margin: auto 10px auto 10px;
}

.currentColor {
  color: white;
}

.auth-content {
  background-color: #ffffff;
  width: 98%;
  border-radius: 5px;
}

.header-img {
  padding-top: 10px;
  margin-right: 40px;
  margin-left: 40px;
  text-align: right;
}

.header-img svg {
  margin-right: 10px;
  margin-left: auto;
}

table td {
  border: none;
}

table .even-row {
  background: #f1f3ff;
}

table .td-first {
  border-left: 1px solid var(--bs-table-border-color);
  width: 10px;
  font-size: 10px;
}

table .td-last {
  border-right: 1px solid var(--bs-table-border-color);
}

table .td-icon {
  padding-top: 4px;
}

table .td-left-border {
  border-left: 1px solid var(--bs-table-border-color);
}

table .td-right-border {
  border-left: 1px solid var(--bs-table-border-color);
}

.selected-table-row,
.selected-table-row a {
  color: #fff !important;
  background-color: #34b4bc !important;
}

table .td-first-none-collapse {
  border-left: 1px solid var(--bs-table-border-color);
}

.table-main thead {
  font-size: 12px;
  line-height: 10px;
  text-transform: capitalize;
  font-weight: 700;
  background: #313b85;
  color: #fff;
}

.table-main tr {
  font-size: 12px;
  line-height: 10px;
}

.table-main td {
  font-size: 12px;
}

.table-sub thead {
  background: #c6c6c6;
  color: #000;
}

.table-form-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 4px;
}

.table-form-label-err {
  color: red;
  font-size: 10px;
  font-weight: 600;
}

.form-control {
  color: #2f2f2f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0;
}

.grid-form-control {
  height: 28px;
}

.form-save-btn {
  font-size: 12px;
  font-family: Poppins;
  line-height: 12px;
  width: 130px;
  height: 30px;
  border-radius: 2px;
  background: #447f3a;
}

.back-btn {
  width: 100px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #002941;
  background: #fff;
  margin-right: 5px;

  color: #002941;

  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.view-more-btn {
  font-size: 12px;
  font-family: Poppins;
  padding: 0;
  color: blue;
  background: #fff;
  border: none;
  margin-top: 5px;
  --bs-btn-hover-color: green;
  --bs-btn-hover-bg: #fff;
}

select.grid-form-control.form-select {
  font-size: 12px;
  line-height: 10px;
  padding: 5px;
  border-radius: 0;
}

.grid-form-control.form-check {
  font-size: 12px;
}

label.form-check-label {
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
}

.react-datepicker__input-container {
  width: 100%;
}

.date-picker-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 5px;
}

input.date-picker {
  height: 28px;
  width: 100%;
  font-size: 12px;
  margin-top: 2px;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.date-picker-icon {
  padding: 0px;
}

/* .react-datepicker__input-container .react-datepicker__calendar-icon {
    display: inline-block;
    background-image: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 16 16' class='bi bi-calendar-fill' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z'/></svg>");
    background-repeat: no-repeat;
} */

.react-datepicker__calendar-icon {
  margin-top: 8px;
  margin-left: 5px;
}

.main-panel {
}

.sub-panel {
  background: linear-gradient(
    90deg,
    rgba(240, 246, 251, 1) 0%,
    rgba(203, 224, 241, 1) 48%,
    rgba(240, 246, 251, 1) 100%
  );
  padding: 0 1rem 0 1rem;
}

.form-main-title {
  width: 100%;
  color: #002941;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.form-title {
  width: 220px;
  color: #fff;
  font-weight: 550;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background: linear-gradient(
    90deg,
    rgba(44, 178, 170, 1) 0%,
    rgba(40, 67, 88, 1) 48%,
    rgba(49, 59, 133, 1) 100%
  );
  box-shadow: 3px 2px 5px 1px rgba(147, 144, 144, 0.75);
  -webkit-box-shadow: 3px 2px 5px 1px rgba(147, 144, 144, 0.75);
  -moz-box-shadow: 3px 2px 5px 1px rgba(147, 144, 144, 0.75);
}

.label-bold {
  color: #2f2f2f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.label-normal {
  color: rgba(47, 47, 47, 0.95);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.auth-modal-body {
  padding: 4rem;
}

.auth-modal-title {
  color: #2f2f2f;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  text-align: center;
}

.auth-modal-sep {
  height: 1px;
  width: 100%;
  background: #dbdddf;
  margin-top: 1rem;
}
