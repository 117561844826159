.selectPatient{
    text-decoration: underline;
}



.fileEdit::before {
   content: "Edit File";
   position: absolute;
   z-index: 2;
   display: block;
   background-color: #eee;
   width: 60px;
 }




