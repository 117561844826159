.req-provider-btn-cont {
    margin-bottom: 80px;
}

.req-provider-btn {
    font-size: 10px;
    line-height: 12px;
    width: 100px;
    background: #313b85;
}

.prev-btn {
    font-size: 10px;
    line-height: 12px;
    width: 100px;
}

.service-provider-btn {
    font-size: 10px;
    line-height: 12px;
    width: 100px;
}

.auth-step-active {
    color: green;
    font-size: 14px;
    font-weight: bold;
}

.auth-step-active {
    font-size: 14px;
    font-weight: bold;
}

.patient-info {
    border: solid 2px #ced4da;
    margin-top: 10px;
}

.info-tittle {
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 10px;
}

.info-desc {
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.info-active {
    width: 10px;
    background: green;
}

