.encounter-patient-info-cont{
    padding: 1rem 1rem 0.5rem 0;
}

.patient-info-label{
    color: #002941;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.patient-view {
    background: #FFF;
    color: #313b85;
    padding: 0;
    border: 0;
    padding-left: 0.6rem;
    font-size: 14px;
}




