.main-view {
    width: 100%;
    height: 100%;
}

.payer-wrapper {
    min-width: 1000px;
}

.view-details-btn-td {
    padding: 0 !important;
    width: 20px !important;
}

.view-details-btn {
    font-size: 12px;
    font-weight: bold;
    height: 10px;
}

.check-add-payer {
    margin-left: 25px;
}

.filter-input {
    width: 300px;
    margin: 0 8px;
}

.filter-btn {
    font-size: 10px;
    line-height: 12px;
    width: 130px;
}

.payer-dropdown {
    height: 28px;
    width: 100%;
    margin-top: 20px;
    color: black;
    background: #fff;
    font-size: 12px;
    border: 1px solid #ced4da;
}

.auth-label {
    margin-right: 10px;
}





