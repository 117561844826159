.navbar-toggler {
    border: none;
    margin-top: 0.8rem;
    background: #fff;
}

.navbar-toggler:focus {
    border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250, 74, 66, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-mobile-cont {
    background: #01043b;
}

.navbar-mobile-btn {
    color: rgba(250, 74, 66, 1);
    text-align: center;
    background: #202151;
    border-radius: 10px;
}

.nav-link:focus, .nav-link:hover {
    color: rgb(255 11 0);
}

.btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath stroke='rgba(250, 74, 66, 1)' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}