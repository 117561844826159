* {
    font-family: Poppins;
}

.viewport-bg {
    width: 100%;
    height: 100%;
    background: var(--Gradient-Background, linear-gradient(180deg, #006096 0%, #11CCBA 58.11%));
}

.login-form {
    width: 350px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.authofi-header {
    font-weight: bolder;
}

.login-title {
    font-weight: bolder;
}

.right-panel {
    background: radial-gradient(50% 50% at 50% 50%,#005ab3 0%,#005ab3 100%);
    width: 350px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.login-subtitle {
    font-size: 12px;
}

.login-form .form-label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
}

.right-panel-label {
    font-family: Poppins;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-top: 25px;
    line-height: 2;
}

.forgot-password-button {
    min-width: 0 !important;
    margin-right: 0 !important;
}

.failed-login-font {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
    overflow: hidden;
}




