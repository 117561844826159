.form-title-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.form-secondary-title {
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
}

.add-patients-btn {
    font-size: 10px;
    font-family: Poppins;
    line-height: 12px;
    min-width: 154px;
    height: 34px;
    background: #002941;
    border-radius: 4px;
}

.grid-form-control .form-check {
    padding-top: 6px;
}

svg.bi.bi-pencil-square {
    margin-right: 10px;
}

.add-icon {
    width: 25px;
    padding-right: 10px;
}



