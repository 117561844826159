.main-view {
    width: 100%;
    height: 100%;
}

.emp-label-select {
    margin-right: 5px;
}

.emp-select-clientId {
    width: 200px;
    height: 25px;
    margin-right: 25px;
}

