.form-title-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.form-secondary-title {
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
}

.grid-form-control .form-check {
    padding-top: 6px;
}

svg.bi.bi-pencil-square {
    margin-right: 10px;
}



