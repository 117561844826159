.navbar-right-panel-cont {
    background: #005ab3;
}

.navbar-right-panel-btn {
    color: rgba(250, 74, 66, 1);
    text-align: center;
    background: #003F7D;
    border-radius: 10px;
}

.header-right-panel-img{
    color:#FFFFFF;
    margin:5px auto 15px auto;
    border-radius: 4px;
    width:50px;
    height:80px;
    text-align:center;
    padding-top:8px;
}

.nav-link:focus, .nav-link:hover {
    color: rgb(255 11 0);
}
