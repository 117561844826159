.clientSelection, a.clientSelection:hover{
    /*text-decoration:none;*/
    color:#000000;
}

.table-invoice thead{
    font-size: 10px;
    line-height: 10px;
    text-transform: capitalize;
    font-weight: 700;
    border-top-color: #000000;
    border-top-style: solid;
    border-top-width: 3px;
}

.table-invoice tbody{
    font-size: 10px;
    font-weight: 700;
}

.invoice-content{
    width:100%;
    min-height:725px;
    border-radius:10px;
    border:1px solid silver;
    box-shadow: rgba(0,0,0,0.8) 0 0 2px;
}

.table-form-label-invoice {
    font-size: 10px;
    font-weight: 700;
    line-height: 11px;
    color:gray;
}

.table-form-label-due{
    font-size: 20px;
    font-weight: 700;
}

.table-form-label-selected{
    font-size: 14px;
    font-weight: 700;
}

.reportsContent{
    width:100%;
    height:95%;
    text-align: center;
        justify-content: center;

}

.reportsContent .tab-content{
    height:95%;
    width:100%;
    margin-top:10px;
    display: flex;
    text-align: center;
    justify-content: center;
}




