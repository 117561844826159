.margin-left-30 {
  margin-left: 30px;
}

.selectPatient {
  color: #0d6efd;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

label.form-check-label.guarantor-same-as-pat {
  font-size: 14px !important;
}

.add-payer-btn {
  font-size: 12px;
  line-height: 12px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002941;
  color: white;
  border-radius: 4px;
  margin-left: 4px;
}

.patient-edit-icon {
  width: 15px;
  margin-left: 7px;
}

.patient-info-header {
  color: #002941;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 3px;
  margin-top: 2px;
}

.patient-guarantor {
  color: #002941;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 2px;
}

.view-doc-btn {
  font-size: 12px;
  line-height: 12px;
  width: 160px;
  height: 34px;
  border-radius: 2px;
  background: #006096;
  margin-left: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.view-fax-btn {
  height: 28px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 2px;
  background: #006096;
  margin-left: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hidden {
  display: none;
}

.patient-title {
  color: #002941;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
