.search {
  margin: 5px 0 10px 40px;
}

.search .form-label {
  color: #2f2f2f !important;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.search .form-control {
  height: 40px;
  width: 180px;
  font-size: 12px;
  /* padding-left: 2px; */
  border-radius: 2px;
  border: 1px solid #ced4da;
}

.search .search-form-group {
  margin-left: 10px;
}

.search-form-group .form-label {
  font-weight: 600;
}

.search-form-group .btn {
  margin-top: 22px;
  background-color: #00304b;
}

.header-filter-btn {
  width: 43px !important;
  height: 43px;
}

.header-search-btn {
  width: 120px !important;
  height: 43px;
}

.search-date-picker {
  height: 40px;
  width: 150px;
  font-size: 12px;
  padding-left: 2px;
  border-radius: 2px;
  border: 1px solid #ced4da;
  margin-top: 5px;
}

.search-date-picker-icon {
  margin-top: 16px;
  margin-left: 6px;
}

.filter-date-picker {
  height: 26px !important;
  width: 234px !important;
}

.mobile-search {
  color: #003f7d;
  text-align: center;
  padding-top: 15px;
  padding-left: 15px;
}

.mobile-search-text {
  font-size: 14px;
}

.search-dob {
  margin-top: 6px;
}

.search-form-group {
  justify-content: flex-end;
}

.header-search {
  width: 70px;
}

.header-filter {
  width: 20px;
}
