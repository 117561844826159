.section-label {
  margin-top: 16px;
  display: flex;
  align-items: end;
}

.add-correspondence-btn {
  font-size: 10px;
  font-family: Poppins;
  line-height: 12px;
  min-width: 225px;
  height: 34px;
  background: #002941;
  border-radius: 4px;
}

.view-file-btn {
  cursor: pointer;
}
