.main-view {
    width: 100%;
    height: 100%;
}

.guarantor-patients-view {
    border: 2px solid #005ab3;
    border-radius: 4px;
}
.view-more {
    cursor: pointer;
}
.line-separator {
    height: 2px;
    width: 100%;
    background-color: #dee2e6;
}

.client-select-orgId {
    margin-left: 10px;
    width: 300px;
    height: 25px;
}

select.grid-form-control.client-select-orgId.form-select {
    font-size: 12px;
}

.input-group-edit {
    height: 28px;
}

.grid-form-control.client-checkbox label.form-check-label {
    margin-top: 0;
    margin-right: 2rem;
}

.client-checkbox label.form-check-label{
    line-height: 17px;
}

.submit-modal {
    margin-left: 10px;
    margin-top: 10px;
}

.client-rate-form {
    padding-left: 40%;
}

.client-rate-control {
    height: 28px;
    font-size: 12px;
    width:50px;
    text-align: center;
}



