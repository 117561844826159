.encounter-form-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 5px;
  margin-right: 5px;
}

.items-end {
  align-items: end;
}

.grid-form-textarea {
  height: 50px;
}

.zip-field {
  width: 60px;
}

.table-rev-pad {
  padding: 0;
}

.line-sep {
  height: 0.1rem;
  background: #b8b8b8;
}

.grid-line-sep {
  height: 0.2rem;
  background: #dee2e6;
}

.app-info-note {
  height: 80px;
}

.add-encounter-btn {
  padding: 0 0 0 0;
}

.border-right-sep {
  border-right: solid 2px #cee2e1;
}

.medical-nes-accordion button.accordion-button.collapsed {
  color: #2f2f2f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.medical-nes-accordion .accordion-button:focus {
  background: #34b4bc;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.medical-nes-accordion .accordion-body {
  color: #2f2f2f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.med-req-btn {
  width: 190px;
  height: 34px;
  border-radius: 2px;
  background: #006096;
  margin-left: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.order-notes {
  height: 172px;
}

.order-info {
  width: 70%;
}

.eligibility-result {
  width: 30%;
}

.elig-info {
  color: #002941;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.elig-info-error {
  color: red !important;
  font-size: 14px;
}

.save-order {
  width: 150px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0px;
}

.reate-datepicker_icon {
  width: 1rem;
  height: 1rem;
}

.eligResult {
  height: 480px;
  overflow: scroll;
}
