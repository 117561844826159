.toggle-button {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.main-form {
  padding: 20px 20%;
}

.portal-dialog {
  margin-top: 10%;
}

.react-datepicker-wrapper {
  display: block;
}
