.left-menu-logo{
    color:orange;
    margin:5px auto 15px auto;
    border-radius: 4px;
    width:50px;
    height:80px;
    text-align:center;
    padding-top:8px;
}

.left-menu-logo-text{
    text-align:center;
    font-size:10px;
    color:#bfdfff;
}

.left-menu{
    color:#FFFFFF;
    border-radius: 4px;
    width: 100%;
    height:50px;
    text-align:center;
    padding-top:4px;
    margin-top: 10px;
}

.left-menu-text{
    color: #FFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 8px 0 0 8px;
}

.left-menu-logout{
    color:#FFFFFF;
    width: 100%;
    height: 150px;
    text-align:center;
    padding-top:8px;
    position: absolute;
    bottom: 0px;
    cursor:pointer;
}

.selected {
    border-left: #FFF solid 5px;
    color:#FFFFFF;
    width: 100%;
    height: 50px;
    text-align:center;
    padding-top:4px;
    background-image: linear-gradient(#006096, #099AA9, #89CEC8);
}

.nav-item-cont {
    margin: 10px 0 0 17px;
}

.user-name {

}

