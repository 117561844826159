.main-view {
    width: 100%;
}

.stat-cont {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-radius: 2px 2px 0px 0px;
    background: #FFF;

}

ul.nav.nav-tabs.nav-fill {
    background: #e8eff6;
}

.nav-tabs {
    --bs-nav-tabs-border-width: 0;
}

.nav-tabs .nav-link {
    color: #6C757D;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.grid-btn {
    height: 32px;
    font-size: 12px;
    vertical-align: middle;
}

.itemsPagination .selected a {
    box-shadow: 0 0 2px #1b7d9e;
    background-color: #f3fcff;
}

.itemsPagination .disabled > a {
    color: #ccc;
    background-color: #f8f8f8;
    border-color: #eee;
    cursor: default;
}

.itemsPagination .disabled > a:hover {
    background-color: #f8f8f8;
}
.itemsPagination .break {
    margin: 0 8px;
}

.itemsPagination .previous {
    margin-right: 1rem;
}

.itemsPagination .next {
    margin-left: 1rem;
}

.pagination {
    padding-top: 4px;
    text-align: center;
    padding-left: 8px;
}

.pagination > ul {
    padding: 0;
    margin: 0;
}

.pagination li {
    display: inline-block;
}

.pagination li > a {
    padding: 0.5rem;
    margin: 1px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #d8eef5;
    border-radius: 2px;
    min-width: 1rem;
}

.pagination li > a:focus {
    outline: none;
}

.submit-btn {
    height: 24px;
    font-size: 9px;
    pointer-events: bounding-box;
}

.align-middle {
    vertical-align: middle;
}

.records {
    height: calc(100% - 64px);
}

.tab-content {
    height: calc(100% - 24px);
}

.tab-pane {
    height: 100%;
}

.header {
    min-height: 40px;
}

.header p {
    margin-bottom: 0;
    line-height: 24px;
}

.auth-row {
    border: 1px solid #313b85;
    border-radius: 4px;
    margin-bottom: 4px;
}


.toggle-column {
    width: 24px;
    padding: 0 0;
    align-items: center;
}

.accordion-collapse {
    padding: 1rem 1rem;
}

.expand-btn {
    height: 22px;
    font-size: 12px;
    border: none;
    background-color: #fff;
}

.border-container {
    border: 1px solid #27273d;
    border-radius: 4px;
}

.documentation-table {
    text-align: center;
    width: 500px;
}

.grid-form-control-select {
    height: 28px;
    font-size: 12px;
    padding: 2px 0.75rem;
    border-radius: 0;
}

.new-box {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-bottom: 6px solid #62AF55;
    border-radius: 4px;
    height: 73px;
    min-width: 250px;
    background-color: #E5FADD;
    color: #62AF55;
}

.affirmed-box {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-bottom: 6px solid #3992FF;
    border-radius: 4px;
    height: 73px;
    width: 250px;
    background-color: #D4E8FF;
    color: #3992FF;
}

.non-affirmed-box {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-bottom: 6px solid #FBAE40;
    border-radius: 4px;
    height: 73px;
    width: 250px;
    background-color: #FFF2DF;
    color: #FBAE40;
}

.return-box {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-bottom: 6px solid #C01243;
    border-radius: 4px;
    height: 73px;
    width: 250px;
    background-color: #FFCEDC;
    color: #C01243;
}

.appeal-box {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-bottom: 6px solid #FC6F3D;
    border-radius: 2px;
    height: 73px;
    width: 250px;
    background-color: #FFD4C5;
    color: #FC6F3D;
}

.filter-round {
    margin: 1rem 1rem;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: #8a3e7a;
}

.filter-icon {
    border-radius: 50%;
    color: #ffffff;
    font-size: 65px;
}

.box-icon {
    margin: 5px 0 0 16px;
    width: 185px;
}

.box-value {
    width: 70px;
}

.box-text {
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px 15px 0 0;
}

.box-subtitle {
    margin: 22px 0 0 16px;
    color: #383838;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.filter-apply-btn {
    font-size: 10px;
    line-height: 12px;
    width: 85px;
    background: #447f3a;
}

.filter-apply-btn2 {
    font-size: 10px;
    line-height: 12px;
    width: 85px;
    background: #447f3a;
}

.filter-clear-btn {
    font-size: 10px;
    line-height: 12px;
    width: 85px;
    background: none;
    border-color: #447f3a;
    font-weight: bold;
}

.filter-clear-btn2 {
    font-size: 10px;
    line-height: 12px;
    width: 85px;
    background: #016096;
}

.filter-cancel-btn {
    font-size: 10px;
    line-height: 12px;
    width: 85px;
    background: #C01243;
}

.filter-user-menu {
    font-size: 12px;
}

.apply-filter-btn {
    font-size: 10px;
    line-height: 12px;
    background: #313b85;
}

.btn-view-document-attach{
    padding:4px !important;
}

.portal-modal-header {
    font-size: 22px;
    font-weight: bold;
}

table.table-portal.table {
    --bs-table-border-color: #ffffff;
}

.table-portal thead{
    color: #002941;
    background-color: #E2F0FF;
    height: 42px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 2px solid #F0F3FB;
    border-top: 2px solid #FFF;
}
.table>thead {
    vertical-align: middle;
}

.table-bordered>:not(caption)>*>* {
    border-width: 0;
}

.table-portal tbody{
    color: #2F2F2F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #DBDDDF;
}

.table-portal .first-td{
    border-left: 1px solid var(--bs-table-border-color);
}

.tooltip-inner {
    text-align: left;
    background: #FFF;
    color: black;
    border: 2px solid #447f3a;
}

.tooltip {
  pointer-events: none !important;
}

.filter-form-group .btn {
    background-color: #00304B;
}


